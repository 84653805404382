
// import store from '@/store'
// console.log('store.getters.memberLevelList',store.getters.memberLevelList);
//用户余额列表
export const balanceCols = [
  {
    prop: 'nickName',
    label: '分销商名称',
    minWidth: '200'
  },
  {
    prop: 'accountName',
    label: '登录账号',
    minWidth: '200'
  },
  {
    prop: 'levelName',
    label: '会员等级',
    minWidth: '200',
    sortable: true
  },
  {
    prop: 'accountAmount',
    label: '账户余额',
    minWidth: '200',
    sortable: true
  },
  {
    prop: 'updateTime',
    label: '更新时间',
    minWidth: '200',
    slotName: 'updateTimeSlot',
    sortable: true
  }
]


export const option = 
    {
      searchLabelWidth: 88,
      selection:true,
      column: [
        {
          prop: 'nickName',
          label: '分销商名称',
          search:true
        },
        {
          prop: 'accountName',
          label: '登录账号',
          search:true
        },
        {
          prop: 'userLevel',
          label: '会员等级',
          search:true,
          type:'select',
          hide:true
        },
        {
          prop: 'userNature',
          label: '分销商性质',
          hide:true,
          search:true,
          type:'select'
        },
        {
          prop: 'levelName',
          label: '会员等级'
        },
        {
          prop: 'accountAmount',
          label: '账户余额'
        },
        {
          prop: 'remark',
          label: '备注'
        },
        {
          prop: 'updateTime',
          label: '更新时间',
          slot: true
        }
      ]
    }
  